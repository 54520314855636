<template>
  <div class="flex flex-col justify-center items-center w-full h-screen">
    <div class="sm:px-2">
      <div class="mb-14 sm:w-72">
        <img
          class="w-[15.375rem] h-[15.375rem] mx-auto mb-8"
          src="/images/Auth/verify-email.svg"
        />
        <h2 class="font-main font-bold text-3xl md:text-xl lg:text-xl">
          Veuillez consulter votre boite mail pour le lien d'activation
        </h2>
        <div class="flex justify-center mt-[3.25rem]">
          <pro-button
            @click="renvoyerEmail"
            type="button"
            class="rounded-lg px-12"
            btn="secondary"
          >
            <span>Renvoyez l'email</span>
          </pro-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  mounted() {
    if (this.user.email_verified_at) {
      this.$router.push('/sections-gu/adresse')
    }
  },
  methods: {
    renvoyerEmail() {
      this.$http.post('grand-publics/resend-email-verification').then(() => {
        this.$toaster.success(
          "Veuillez consulter votre boite mail pour le lien d'activation",
        )
      })
    },
  },
}
</script>

<style scoped>
.reset-form {
  width: 420px;
}
@media (max-width: 768px) {
  .reset-form {
    width: 350px;
  }
}
@media (max-width: 639px) {
  .reset-form {
    width: 300px;
    margin: 0 auto;
  }
}
</style>
